.screens{
    min-width:100vh;
    min-height:100vh;
}

.sidebarcontainer1{

    display: flex;
    flex-direction: row;
    justify-content: center; 
    align-items: center;
}
.sidebarstart{
   
    position:absolute;
    width:151px;
    height:auto;
    top:15px;
    left:15px;
    object-fit: contain,cover;
    background-image: url(../../../imgs/timertxt.png);
    background-repeat: no-repeat;
    background-size: contain;
    display: flex;
    flex-direction: row;
    justify-content: space-around; 
    align-items: center;

    /* padding-top: 4%; */
}

.sidebarend{
   
    position:absolute;
    width:141px;
    height:166px;
    top:15px;
    right:15px;
    object-fit: contain,cover;
    background-image: url(../../../imgs/blacktextr.png);
    background-repeat: no-repeat;
    background-size: cover,contain;

}

.timertxt{
    font-size: 28px;
    font-family: Impact, Haettenschweiler, 'Arial Narrow Bold', sans-serif;
    color:khaki;
}

.howbutton{
    position:absolute;
    width:141px;
    height:166px;
    bottom:145px;
    right:15px;

    object-fit: contain;
    background-image: url(../../../imgs/howtoplayicon.png);
    background-repeat: no-repeat;
    background-size: contain;
    display: flex;
    flex-direction: column;
    justify-content: center; 
    align-items: center;
    background-color: transparent;
    text-align: center;
    color:brown;
    padding-top: 12%;
    opacity: 0.6;
    font-size: 28px;
    font-family: Impact, Haettenschweiler, 'Arial Narrow Bold', sans-serif;
    border-radius: 0px;
    border:0px;
    box-shadow: 0 5px transparent;
    cursor: pointer;
    display: block;


}
.bubblestyle{
    position:absolute;
    width:200px;
    height:90px;
    bottom:300px;
    left:133px;
    
    object-fit: contain;
    background-image: url(../../../imgs/bubble.svg);
    background-repeat: no-repeat;
    background-size: 100% 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    padding-left: 20px;
    padding-right: 3px;
    padding-bottom: 3px;
    padding-top: 3px;

    color: gray;
    font-size: 15px;
    text-align: center;
    font-family: sans-serif;
}


.bubblestyledark{
    position:absolute;
    width:200px;
    height:90px;
    bottom:300px;
    left:133px;
    
    object-fit: contain;
    background-image: url(../../../imgs/bubbledark.svg);
    background-repeat: no-repeat;
    background-size: 100% 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    padding-left: 20px;
    padding-right: 3px;
    padding-bottom: 3px;
    padding-top: 3px;

    color: gray;
    font-size: 15px;
    text-align: center;
    font-family: sans-serif;
}


.bubbletext{
    flex:1;
    width:100%;
    height:90%;
  
    max-height:90%;
    overflow-y: scroll;
    overflow-x: initial;
    
    object-fit: contain;

    padding-right: 4px;
    
    padding-bottom: 13px;
    padding-top: 3px;
    display: flex;
    justify-content: center;
    align-items: center;

    color: gray;
    font-size: 15px;
    text-align: center;
    font-family: sans-serif;

}



.bubbletextdark{
    /* position:absolute; */
    flex:1;
    width:100%;
    height:90%;
  
    max-height:90%;
    overflow-y: hidden;
    overflow-x: initial;
    object-fit: contain;
    padding-right: 4px;
    
    padding-bottom: 13px;
    padding-top: 3px;
    display: flex;
    justify-content: center;
    align-items: center;

    color: khaki;
    font-size: 15px;
    text-align: center;
    font-family: sans-serif;

}

.avatarstyle{
    position:absolute;
    width:180px;
    height:180px;
    bottom:150px;
    left:15px;
    object-fit: contain;
}

.txtboxcat{
    position:absolute;
    width:179px;
    height:144px;
    top:40px;
    right:121px;
    text-align: center;
    object-fit: contain,cover;
    background-image: url(../../../imgs/textboxr.png);
    background-repeat: no-repeat;
    background-size: contain;
}
.txtboxbp{

    position:absolute;
    width:179px;
    height:144px;
    top:90px;
    right:121px;
    text-align: center;
    object-fit: contain,cover;
    background-image: url(../../../imgs/textboxr.png);
    background-repeat: no-repeat;
    background-size: contain;
}
.txtcat{
    position: absolute;
    top:-1px;
    left:35px;
    color: khaki;
    font-size: 20px;
    font-family: sans-serif;
    align-items: center;
}

.txtr{
    position:absolute;
    right:30px;

    padding-top: 24px;
    color: khaki;
    font-size: 28px;
}

.txtrno{
    position:absolute;
    padding-top: 70px;
    right:50px;

    padding-bottom: 15%;
    color: khaki;
    font-size: 48px;
    font-family: 'Courier New', Courier, monospace;
}

.sidebartopright{
    flex:1;
    width:36px;
    height:52px;
    padding-bottom: 2%;
    object-fit: contain;
}

.sidebartoprighttxt{
    flex:1;
    color: khaki;
    font-size: 28px;
    text-align: center;
    font-family: SHPinscher;
}

.gamescreendiv{
    width: 100vh;
    height: 100vh;

  }
  
  .howbutton:hover{opacity: 1;
    filter: brightness(120%);
}

.howbutton:active{
    box-shadow: 0 5px transparent;
  transform: translateY(4px);
}


  .totalreferhead{
    text-align: center;
    font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
    font-size: 24px;
    color: #ffff00;
  }
  
  .totalrefertxt{
    text-align: center;
    font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
    font-size: 24px;
    color: white;
  }
  
  .Demo__some-network {
    vertical-align: bottom;
    display: inline-block;
    margin-right: 10px;
    text-align: center;
  }
  
  .Demo__some-network__share-count {
    display: inline-flex;
    justify-content: flex-end;
    white-space: nowrap;
    overflow: visible;
    vertical-align: bottom;

    width: 0;
    margin-top: 3px;
    font-size: 12px;
  }
  
  .Demo__some-network__share-button {
    cursor: pointer;
  }
  
  .Demo__some-network__share-button:hover:not(:active) {
    opacity: 0.75;
  }
  
  .Demo__some-network__custom-icon {
    width:32px;
    height:32px;
  }
  /* unvisited link */
a:link {
  color: white;
}

/* visited link */
a:visited {
  color: #ffff00;
}

/* mouse over link */
a:hover {
  color: hotpink;
}

/* selected link */
a:active {
  color: #ffff00;
}
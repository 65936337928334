.pathcontainer{
    position: absolute;
    width:1020px;
    left:130px;
    top:0px;
    bottom:10px;
}

/* 1 */

.p1{
position: absolute;
left: 25.39%;
right: 73.09%;
top: 15.62%;
bottom: 82.36%;
}


/* 2 */

.p2{
position: absolute;
left: 28.85%;
right: 69.62%;
top: 16.94%;
bottom: 81.04%;
}


/* 3 */

.p3{
position: absolute;
left: 32.29%;
right: 66.19%;
top: 18.07%;
bottom: 79.91%;
}


/* 4 */

.p4{
position: absolute;
left: 36.12%;
right: 62.35%;
top: 19.2%;
bottom: 78.78%;
}


/* 5 */

.p5{
position: absolute;
left: 39.96%;
right: 58.52%;
top: 20.33%;
bottom: 77.66%;
}


/* 6 */

.p6{
position: absolute;
left: 51.84%;
right: 46.64%;
top: 25.91%;
bottom: 72.08%;
}


/* 7 */

.p7{
position: absolute;
left: 55.39%;
right: 43.09%;
top: 27.1%;
bottom: 70.88%;
}


/* 8 */

.p8{
position: absolute;
left: 59.3%;
right: 39.17%;
top: 28.23%;
bottom: 69.75%;
}


/* 9 */

.p9{
position: absolute;
left: 63.06%;
right: 35.42%;
top: 29.99%;
bottom: 67.99%;
}


/* 10 */

.p10{
position: absolute;
left: 67.05%;
right: 31.42%;
top: 31.12%;
bottom: 66.87%;
}


/* 13 */

.p13{
position: absolute;
left: 80.32%;
right: 18.16%;
top: 43.66%;
bottom: 54.32%;
}


/* 11 */

.p11{
position: absolute;
left: 76.38%;
right: 22.1%;
top: 35.69%;
bottom: 62.29%;
}


/* 12 */

.p12{
position: absolute;
left: 79.36%;
right: 19.11%;
top: 39.4%;
bottom: 58.58%;
}


/* 22 */

.p22{
position: absolute;
left: 40.12%;
right: 58.36%;
top: 56.71%;
bottom: 41.27%;
}


/* 23 */

.p23{
position: absolute;
left: 36.92%;
right: 61.55%;
top: 60.35%;
bottom: 37.63%;
}


/* 21 */

.p21{
position: absolute;
left: 50.83%;
right: 47.65%;
top: 53.58%;
bottom: 44.41%;
}


/* 20 */

.p20{
position: absolute;
left: 55.39%;
right: 43.09%;
top: 53.32%;
bottom: 44.66%;
}


/* 19 */

.p19{
position: absolute;
left: 60.1%;
right: 38.38%;
top: 52.95%;
bottom: 45.03%;
}


/* 18 */

.p18{
position: absolute;
left: 63.86%;
right: 34.62%;
top: 52.57%;
bottom: 45.41%;
}


/* 17 */

.p17{
position: absolute;
left: 68.57%;
right: 29.9%;
top: 51.69%;
bottom: 46.29%;
}


/* 16 */

.p16{
position: absolute;
left: 72.65%;
right: 25.83%;
top: 51.19%;
bottom: 46.79%;
}


/* 15 */

.p15{
position: absolute;
left: 76.41%;
right: 22.07%;
top: 50.69%;
bottom: 47.29%;
}


/* 14 */

.p14{
position: absolute;
left: 79.36%;
right: 19.11%;
top: 48.06%;
bottom: 49.93%;
}


/* 24 */

.p24{
position: absolute;
left: 36.2%;
right: 62.27%;
top: 66%;
bottom: 31.98%;
}


/* 25 */

.p25{
position: absolute;
left: 39.96%;
right: 58.52%;
top: 69.51%;
bottom: 28.47%;
}


/* 26 */

.p26{
position: absolute;
left: 44.04%;
right: 54.44%;
top: 71.52%;
bottom: 26.46%;
}


/* 27 */

.p27{
position: absolute;
left: 48.83%;
right: 49.64%;
top: 72.52%;
bottom: 25.46%;
}


/* 28 */

.p28{
position: absolute;
left: 53.87%;
right: 44.61%;
top: 73.02%;
bottom: 24.96%;
}


/* 29 */

.p29{
position: absolute;
left: 57.78%;
right: 40.69%;
top: 73.53%;
bottom: 24.46%;
}


/* 30 */

.p30{
position: absolute;
left: 63.14%;
right: 35.34%;
top: 74.15%;
bottom: 23.83%;
}


/* 31 */

.p31{
position: absolute;
left: 68.17%;
right: 30.3%;
top: 73.53%;
bottom: 24.46%;
}


/* 32 */

.p32{
position: absolute;
left: 72.65%;
right: 25.83%;
top: 72.4%;
bottom: 25.58%;
}


/* 33 */

.p33{
position: absolute;
left: 77.12%;
right: 21.35%;
top: 72.4%;
bottom: 25.58%;
}


/* 34 */

.p34{
position: absolute;
left: 81.04%;
right: 17.44%;
top: 71.27%;
bottom: 26.71%;
}


/* 35 */

.p35{
position: absolute;
left: 85.12%;
right: 13.36%;
top: 69.51%;
bottom: 28.47%;
}


.round1{
    position: absolute;
    width: 81px;
    height: 123px;
left: 16.39%;
right: 82.09%;
top: 4.62%;
bottom: 91.36%;
    }
    .round1b{
        position: absolute;
        width: 81px;
        height: 90px;
        left: 16.39%;
        right: 82.09%;
        top: 8.62%;
        bottom: 82.36%; 
       }
    
    .round1h{
        position: absolute;
        width: 53px;
        height: 60px;
        left: 17.9%;
        right: 80.09%;
        top: 8.62%;
        bottom: 82.36%; 
    }
    
     /* second */
    
     .round2{
        position: absolute;
        width: 81px;
        height: 121px;
        left: 42.84%;
        right: 56.64%;
        top: 10.91%;
        bottom: 88.08%;
       
        }
        .round2b{
            position: absolute;
            width: 81px;
            height: 90px;
            left: 42.84%;
            right: 56.64%;
            top: 14.91%;
            bottom: 79.08%;
            }
            
        
        .round2h{
        
            position: absolute;
            width: 53px;
            height: 60px;
            left: 44.34%;
            right: 56.64%;
            top: 14.91%;
            bottom: 88.08%;
            
        }
        .round3{
            position: absolute;
            width: 81px;
            height: 115.3px;
            left: 70.05%;
        right: 30.42%;
        top: 24.12%;
        bottom: 74.87%;
            }
            
            .round3h{
                position: absolute;
                width: 53px;
                height: 60px;
                left: 71.45%;
                right: 28.42%;
                top: 27.12%;
                bottom: 74.87%;
                }
                    
            .round4{
                position: absolute;
                width: 81px;
                height: 113px;
                left: 42.39%;
                right: 58.09%;
                top: 43.32%;
                bottom: 47.66%;
                }
            .round4h{
                position: absolute;
                width: 53px;
                height: 60px;
                left: 43.79%;
                right: 58.09%;
                top: 46.32%;
                bottom: 43.66%;
                }
    /* fifth */
    
    .round5{
        position: absolute;
        width: 81px;
        height: 114px;
        left: 85.12%;
        right: 15.36%;
        top: 55.51%;
        bottom: 48.47%;
        }
        
        .round5h{
            position: absolute;
            width: 53px;
            height: 60px;
            left: 86.42%;
            right: 13.36%;
            top: 58.51%;
            bottom: 42.47%;
            }
                     
    .roundbb12 {
        width: 81px;
        height: 90px;
        left: 42.94%;
        right: 56.64%;
        top: 14.91%;
        bottom: 79.08%;
        position: absolute;
        animation-name: round12b;
        animation-duration: 0.2s;
        animation-timing-function: linear;
    }
    .roundbb23 {
        width: 81px;
        height: 90px;
        left: 70.05%;
        right: 30.42%;
        top: 26.12%;
        bottom: 74.87%;
        position: absolute;
        animation-name: round23b;
        animation-duration: 0.2s;
        animation-timing-function: linear;
    }
    
    .roundbb34 {
        width: 81px;
        height: 90px;
        left: 42.39%;
        right: 58.09%;
        top: 46.32%;
        bottom: 43.66%;
        position: absolute;
        animation-name: round34b;
        animation-duration: 0.2s;
        animation-timing-function: linear;
    }
    .roundbb45 {
        width: 81px;
        height: 90px;  
        left: 85.12%;
        right: 15.36%;
        top: 58.51%;
        bottom: 41.47%;
    
        position: absolute;
        animation-name: round45b;
        animation-duration: 0.2s;
        animation-timing-function: linear;
    }
  
    
    @keyframes round12b {
        0%   {left: 83px;top: 80px;}
        100% { left: 42.94%;
            right: 56.64%;
            top: 14.91%;
            bottom: 79.08%;}
    }
    
    @keyframes round23b {
        0%   { left: 42.94%;
            right: 56.64%;
            top: 14.91%;
            bottom: 79.08%;}
        100% { left: 70.05%;
            right: 30.42%;
            top: 26.12%;
            bottom: 74.87%;}
    }
    @keyframes round34b {
        0%   { left: 70.05%;
            right: 30.42%;
            top: 26.12%;
            bottom: 74.87%;}
        100% { left: 42.39%;
            right: 58.09%;
            top: 46.32%;
            bottom: 43.66%;}
    }
    @keyframes round45b {
        0%   { left: 42.39%;
            right: 58.09%;
            top: 46.32%;
            bottom: 43.66%;}    
        100% {    left: 85.12%;
            right: 15.36%;
            top: 58.51%;
            bottom: 41.47%;}
    }
 
    
    
    
    
    /* sixth */
    .roundee{
        width: 81px;
        height: 90px;left: 354px;top: 134px;
        /* background-color: red; */
        position: absolute;
        animation-name: round12b;
        animation-duration: 0.2s;
        animation-timing-function: linear;
    }
    .round6{
    position: absolute;
    width: 81px;
    height: 117px;
    left: 933px;
    top: 364px;
    }
    .round6h{
        position: absolute;
        width: 53px;
        height: 60px;
        left: 948px;
        top: 391px;
        }
        
    .round6b{
    
        position: absolute;
        width: 81px;
        height: 90px;
        left: 933px;
        top: 404px;
        
    }
    
    /* seventh */
    
    .round7{
    position: absolute;
    width: 81px;
    height: 117px;
    left: 1146px;
    top: 279px;
    }
    
    .round7h{
        position: absolute;
        width: 53px;
        height: 60px;
        left: 1161px;
        top: 312px;
        }
    .round7b{
    
        position: absolute;
        width: 81px;
        height: 90px;
        left: 1146px;
        top: 308px;  
    }
    
   
    

    
    /* fourth */
    
  
    /* third */
    
   
    
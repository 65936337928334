* {
  margin: 0;
  padding:0;
  box-sizing: border-box;
  font-family:Impact, Haettenschweiler, 'Arial Narrow Bold', sans-serif;

}
@font-face {
  font-family: 'HighJinks';
  src: local('HighJinks'), url(./fonts/High-Jinks.otf) format('truetype');
}

@font-face {
  font-family: 'SHPinscher-Regular';
  src: local('SHPinscher-Regular'), url(./fonts/SHPinscher-Regular.otf) format('truetype');
}

code {
  font-family: 'HighJinks';

}

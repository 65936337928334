.modalwincontainer{
    position: fixed;
    height: 100%;
    width:100%;
    display: flex;
    justify-content: center; 
    align-items: center;
    text-align: center;
    z-index:  100;
}

.modalwin{
    object-fit: contain;
    width:100vh;
    height:100vh;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    display: flex;
    justify-content: center;
    align-items: flex-end;

    
   
}

.quitbuttonscontainer{

    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-end;
    width:25%;
    height:20%;
    margin-bottom: 25%;

}

.quitbutton{
   
    flex:1;
    width:100%;
    height:100%;
    background-size: contain;
    display: block;
    background-image: url(../../../imgs/result/redbutton.png);
    background-color: transparent;
    background-position: center;
    background-repeat: no-repeat;
    border-radius: 0px;
    border:0px;
    cursor: pointer;


    text-align: center;
    font-family: Impact, Haettenschweiler, 'Arial Narrow Bold', sans-serif;
    font-size: 24px;
    color: bisque;
    opacity:1;
    padding-bottom: 0.5%;
    }
    
    .sharebutton{
        flex:1;
        width:100%;
        height:100%;
        background-size: contain;
        display: block;
        background-image: url(../../../imgs/result/bluebutton.png);
        background-color: transparent;
        background-position: center;
        background-repeat: no-repeat;
        border-radius:0px;
        border:0px;
        cursor: pointer;
        text-align: center;
        font-family: Impact, Haettenschweiler, 'Arial Narrow Bold', sans-serif;
        font-size: 24px;      
        color: bisque;
        opacity:1;
        }

        .sharebutton:hover{opacity: 1;
            filter: brightness(120%);
        }
        
        .sharebutton:active{
          box-shadow: 0 5px transparent;
          transform: translateY(4px);
        }
        .quitbutton:hover{opacity: 1;
            filter: brightness(120%);
        }
        
        .quitbutton:active{
            box-shadow: 0 5px transparent;
            transform: translateY(4px);
        }
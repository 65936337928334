
html,
body,
#root {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  background-image: url(./imgs/bgcolor.png);
  background-repeat: no-repeat;

}
#root, .App {
  min-width:100%;
  height:100vh;
  overflow:hidden; 
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;

}

.gamescreendiv{
  display: flex;
  flex-direction: row;
  justify-content: center; 
  align-items: center;
}


.loader {
  position: absolute;
  z-index:300;
  content: url(./imgs/logoicon.png);
   top: calc(50% - 4em);
  left: calc(50% - 4em);
  width: 6em;
  height: 6em;
  border: 1.1em solid rgba(216, 223, 220, 0.2);
  border-left: 1.1em solid #f5a91d;
  border-radius: 100%;
  animation: load8 1.1s infinite linear;
  transition: opacity 0.3s;
}

.loader--hide {
  opacity: 0;
}

@keyframes load8 {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}


.card {
 
    /* border-radius: 5px; */
    /* background-image: url(../imgs/cards/travel/bicycle.png); */
    width:183px;
    height:250px;
    background-size: contain;
    background-repeat: no-repeat;
    z-index: 111;
    margin-bottom: 5%;
    margin-top: 5%;
    
    cursor: pointer;

    background-position: center center;
    /* box-shadow: 0px 10px 30px -5px rgba(0, 0, 0, 0.3); */
    transition: box-shadow 0.5s;
    will-change: transform;
    /* border: 15px solid white; */
  }

  .cardimg{
    width:183px;
    height:250px;
    object-fit: contain;
    
  }
  
  .disabledcard {
 
    /* border-radius: 5px; */
    /* background-image: url(../imgs/cards/travel/BYCYCLE.png); */
    width:183px;
    height:250px;
    background-size: contain;
    background-repeat: no-repeat;
    z-index: 111;
    margin-bottom: 5%;
    margin-top: 5%;
    
    /* cursor: not-allowed; */

    background-position: center center;
    /* box-shadow: 0px 10px 30px -5px rgba(0, 0, 0, 0.3); */
    /* transition: box-shadow 0.5s;
    will-change: transform; */
    /* border: 15px solid white; */
  }
  
  .card:hover {
    box-shadow: 0px 30px 100px -10px rgba(0, 0, 0, 0.4);
  }
  
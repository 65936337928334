.bcontainer{
    position: absolute;
  
    overflow-y: auto;
    width:100%;
    height:100%;
    text-align: center;
    display: flex;
    align-content: center;
    justify-content: center;
}
.bcontainer::-webkit-scrollbar {
    display: none;

  }
  
  /* Hide scrollbar for IE and Edge */
  .bcontainer {
    -ms-overflow-style: none;
  }


.boardcontainer{
    position: absolute;
    width:700px;
    height:1120px;
    margin-bottom: 5%;
    text-align: center;
    top:0%;
    display: block;
    z-index: 99;

    background-image: url(../../../imgs/cardboard.png);
    background-repeat: no-repeat;
    background-position-x: center;
    background-position-y: top;
    background-size: cover;
}

.headingchoose{
    object-fit: contain;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: top;
}

.statusvs{
    font-size: 20px;
    object-fit: contain;
    padding-top: 50%;
    color: black;
    font-family:-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    text-align: center;  
}


.statuswin{
    font-size: 28px;
    /* padding-block-start: 2%; */
    padding-top: 44%;
    color: green;
    font-family:'Courier New', Courier, monospace;
    text-align: center;  
}


.statuslose{
    font-size: 28px;
    /* padding-block-start: 2%; */
    padding-top: 44%;
    color: firebrick;
    font-family:'Courier New', Courier, monospace;
    text-align: center;  
}

.statusdraw{
    font-size: 28px;
    /* padding-block-start: 2%; */
    padding-top: 55%;
    color:blue;
    font-family:'Courier New', Courier, monospace;
    text-align: center;  
}

.nextbutton{
    background-image:url(../../../imgs/nextcard.png);
    background-repeat: no-repeat;
    background-size: contain;
    background-color: transparent;
    display: block;
     border-radius: 0px;
    border:0px;
    width:50px;
    height:50px;
    position: absolute;
    right:2.7%;
    top:32%;
    object-fit: contain;
    z-index: 100;
    cursor: pointer;
}
.nextbutton:hover{
    width:75px;
    height:75px;
    right:1.7%;
    top:31%;

    filter: brightness(120%);
}
.backbutton{
    background-image:url(../../../imgs/backcard.png);
    background-repeat: no-repeat;
    background-size: contain;
    background-color: transparent;
    display: block;
     border-radius: 0px;
    border:0px;
    width:50px;
    height:50px;
    position: absolute;
    left:3%;
    top:32%;
    /* opacity: 0.7; */
    object-fit: contain;
    z-index: 100;
    cursor: pointer;
}

.backbutton:hover{
    width:75px;
    height:75px;
    left:2%;
    top:31%;

    filter: brightness(120%);
}
.vscontainer{
        position: absolute;
        left:9.8%;
        top:22%;
        width:80%;
        max-width: 80%;;
        height:43%;
    
         overflow-x: unset;
        overflow-y: hidden;
        white-space:nowrap; 
        scroll-behavior: smooth;
}

.vscontainer::-webkit-scrollbar {
    display: none;

  }
  
  /* Hide scrollbar for IE and Edge */
  .vscontainer {
    -ms-overflow-style: none;
  }
  

.slide{
    display: inline-block;

    padding: 1%;
}
.cardcontainer{
    position: absolute;
    object-fit: contain;
    
    left:18.6%;
    bottom:5%;
    width:70%;
    height:23%;
    display: grid;
    grid-template-columns: repeat(3,1fr);
    grid-column-gap: 2%;
    padding: 1%;

}

.bordergap{
    opacity: 0.2;
}

.rowarrow{
    object-fit: contain;
    display: absolute;
    width:100%;
    text-align: center;
    padding-top: 20%;

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: bottom;

}
.prpos{
    
    object-fit: contain;
    width:40px;
    height: 40px;
    margin-right: 10%;

}

.crpos{
    object-fit: contain;
    width:40px;
    height: 40px;
}
  
  .arrow-rotate-cr{
    position: absolute;
 bottom:-21%;
 z-index: 10;
 object-fit: contain;
 width:40px;
 height: 80px;
  transform: rotate(15deg)  translateX(10px);

  transition: transform 500ms linear;

 }
 .arrow-rotate-pr{
    position: absolute;
 bottom:-21%;
 z-index: 10;
 object-fit: contain;
 width:40px;
 height: 80px;
   transform: rotate(-15deg)  translateX(-10px); 

  transition: transform 500ms linear;

 }
 .arrow-rotate{
       position: absolute;
    bottom:-21%;
    /* left:47%; */
    z-index: 10;
    object-fit: contain;
    width:40px;
    height: 80px;
    transform:rotate(0deg);

     transition: transform 500ms linear;
  
    }
   .arrowcontainer{
    position: absolute;
    bottom:-21.5%;
    width:45px;
    height: 14px;
    z-index: 100;
   }
@charset "utf-8";

.screen{
    display: flex;
  flex-direction: row;
  justify-content: space-between; 
  align-items: flex-start;
}
.left{
    flex:1;
    display: flex;
  flex-direction: column;
  justify-content: flex-start; 
  align-items: flex-start;
}


.left1{
    flex:1;
    display: flex;
  flex-direction: row;
  justify-content: flex-start; 
  align-items: flex-end;
}
.imgcontain1{
    flex: 1;
    object-fit: contain;
    height: auto;
}

.splashimg{ 
    flex: 1;
    display: flex;
    flex-direction: column;

    justify-content: center;
    align-content: space-between;
    
}

.splashimg1{
flex:3;
background-size: 100% 100%;
}

.playbutton{
flex:1;
background-image: url(../../../imgs/greenplay.png);
background-color: transparent;
background-position: center;
background-repeat: no-repeat;
border-radius: 0px;
border:0px;
width: 272px;
height: 110px;
cursor: pointer;

padding-top: 4%;
padding-bottom: 4%;
text-align: center;
font-family: HighJinks;
font-size: 38px;
color: bisque;
opacity:1;
object-fit: cover;
margin-top: -27%;
margin-left: 25%;
margin-right: 25%;

}


.refertxt{
    flex:1;    
    padding-top: 4%;
    padding-bottom: 4%;
    text-align: center;
    font-family: 'Times New Roman', Times, serif;
    font-size: 15px;
    color: bisque;
    opacity:1;
    object-fit: contain;
    margin-top: -4%;
    margin-left: 12%;
    margin-right:12%;
    }
    
.loginbutton{
    flex:1;
    background-image: url(../../../imgs/loginbutton.png);
    background-color: transparent;
    background-position: center;
    background-repeat: no-repeat;
    border-radius: 0px;
    border:0px;
    width: 272px;
    height: 110px;
    cursor: pointer;
    padding-left: 8%;
    display: block;
    
    padding-top: 4%;
    padding-bottom: 4%;
    text-align: center;
    font-family: HighJinks;
    font-size: 38px;
    
    color: bisque;
    opacity:1;
    object-fit: cover;
    margin-top: -27%;
    margin-left: 25%;
    margin-right: 25%;
    }
    
    .cancelbutton{
        position: absolute;
        background-image: url(../../../imgs/cancelbutton.png);
        background-size: contain;
        background-repeat: no-repeat;
        background-color: transparent;
        padding-right: 2%; 
        cursor: pointer;
        display: block;
         border-radius: 0px;
        border:0px;
        width:15%;
        height:15%;
        right:22%;
        top:5%;
    }

    .referbuttons{
        position: absolute;
        padding-right: 2%; 
        cursor: pointer;
        display: block;
         border-radius: 0px;
        border:0px;
        width:50%;
        height:15%;
        left:18%;
        text-align: center;
        bottom:20%;
    }

    .view100{
        position: absolute;

        background-size: contain;
        background-repeat: no-repeat;
        top:0;
        width:100vh;
        left:30.5%;
        height:88vh;
        z-index: 300;
    }
    .sidetextbox{
        flex:1;
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        justify-content: center; 
        padding-right: 2%;

    }


.buttonsdiv{
    flex: 1;
    position: absolute;
    padding: 2%;
}

.logouttxt{
    color: bisque;
    background:transparent;
    font-family: 'Courier New', Courier, monospace;
    padding-right: 4%;
    box-shadow: 0 5px transparent;
    font-size: 22px;

    border-radius: 0px;
    border:0px;
}

.b1{
    opacity:0.9;
    position: absolute;
    bottom:230px;
    left:140px;
    z-index: 200;
	background-repeat : no-repeat;
    background-size: contain;
    background-color: transparent;
    padding: 22%;
    cursor: pointer;
    display: block;
     border-radius: 0px;
    border:0px;
    background-size: 100% 100%;
    width: 200px;
    height:auto; 
    text-align: center;
    font-family: HighJinks;
    font-size: 32px;
    color: white;
    box-shadow: 0 5px transparent;

}

.b2{
    opacity:0.9;
    position: absolute;
    bottom:120px;
    left:140px;
    z-index: 200;
	background-repeat : no-repeat;
    background-size: contain;
    background-color: transparent;
    padding: 22%;
    cursor: pointer;
    display: block;
     border-radius: 0px;
    border:0px;
    background-size: 100% 100%;
    width: 200px;
    height:auto; 
    text-align: center;
    font-family: HighJinks;
    font-size: 32px;
    color: white;
    box-shadow: 0 5px transparent;

}
.b3{
    opacity:0.9;
    position: absolute;
    bottom:10px;
    z-index: 200;
    left:140px;
	background-repeat : no-repeat;
    background-size: contain;
    background-color: transparent;
    padding: 22%;
    cursor: pointer;
    display: block;
     border-radius: 0px;
    border:0px;
    background-size: 100% 100%;
    width: 200px;
    height:auto; 
    text-align: center;
    font-family: HighJinks;
    font-size: 32px;
    color: white;
    box-shadow: 0 5px transparent;

}


.b1:hover { opacity:1;
    filter: brightness(120%);
}
.b2:hover { opacity:1;
    filter: brightness(120%);
}
.b3:hover { opacity:1;
    filter: brightness(120%);
}
.playbutton:hover{opacity: 1;
    filter: brightness(120%);
}

.playbutton:active{
    box-shadow: 0 5px transparent;
  transform: translateY(4px);
}

.loginbutton:hover{opacity: 1;
    filter: brightness(120%);
}

.loginbutton:active{
    box-shadow: 0 5px transparent;
  transform: translateY(4px);
}

button:focus {outline:0;}
.logouttxt:hover{
    text-decoration: underline overline dotted bisque;
}

.rockbottom{
    background: url(../../../imgs/backrock.png);
}


.bottomhud{
  position: fixed;
  /* width: auto;
  height: 15%; */
  width: 100%;
  z-index: 200;
 /* padding-left: 4%;
  padding-right: 4%; */

  /* object-fit: cover,contain; */
  /* position: fixed; */
  /* left: 0; */
  bottom: 0px;
  display: flex;
  flex-direction: row;
  justify-content: center; 
  align-items: center;
  /* width: 100%; */

  /* bottom: 0%; */
}


.imgfooter{
  /* margin: 0 auto;
  margin-left: auto;
   margin-right: auto ; */
   /* flex:1; */
   position: fixed;
   bottom:0px;
  width:1162px;
  height:142px;
   /* object-fit: contain; */
   /* margin-bottom: -0.5%; */
}
/* bottom area base23 1 */

.bbase{
/* position: absolute; */
/* width: 100%; */
/* object-fit: contain; */
position: absolute;
width:1162px;
height:142px;
bottom:0px;

/* height: auto; */
}

/* player stats */

.lstats{
position: absolute;
left:3%;
bottom:10%;
width: 242px;
height: 80px;
}

.lplayer{
  position: absolute;
  left:11%;
  top:0%;
  color: khaki;
  font-size: 30px;
  font-family: Impact, Haettenschweiler, 'Arial Narrow Bold', sans-serif;
  }

.ltoptxt{
  position: absolute;
  left:12%;
  /* top:22%; */
  bottom:22%;
  /* width: 242px;
  height: 80px; */
  color: khaki;
  font-size: 20px;
  font-family: Impact, Haettenschweiler, 'Arial Narrow Bold', sans-serif
    }
  

.lbottomtxt{
  position: absolute;
 left:12%;
 bottom:0%;
 width: 242px;
 height: 80px;
 color: chocolate;
 font-size: 20px;
 font-family:Impact, Haettenschweiler, 'Arial Narrow Bold', sans-serif;

}
  /* rtop */
  

  .rtoptxt{
    position: absolute;
    right:12%;
    /* top:22%; */
    bottom:20%;
    /* width: 242px;
    height: 80px; */
    color: khaki;
    font-size: 20px;
    font-family: Impact, Haettenschweiler, 'Arial Narrow Bold', sans-serif
      }
    
  
  .rbottomtxt{
    position: absolute;
   right:-2%;
   bottom:0%;
   width: 242px;
   height: 80px;
   color: chocolate;
   font-size: 20px;
   font-family:Impact, Haettenschweiler, 'Arial Narrow Bold', sans-serif;
  
  }
    
/* player stats */

.rplayer{
  position: absolute;
  right:7%;
  top:0%;
  color: khaki;
  font-size: 30px;
  font-family: Impact, Haettenschweiler, 'Arial Narrow Bold', sans-serif;
  }

.rstats{
  right:3%;
bottom:10%;

position: absolute;
width: 242px;
height: 80px;

}


/* rbottom */



/* bar */

.bar{
position: absolute;
left: 34%;
/* right:40%; */
bottom:10%;
width: 412px;
height: 39px;

}

.bargrid{
  position: absolute;
  left: 35%;
  /* right:40%; */
  bottom:15%;
  width: 370px;
  height: 20px;
  display: grid;
  grid-template-columns: repeat(5,1fr);
  grid-column-gap: 1%;

  grid-template-rows: 1fr; 
  
  }
.barrgreen{
  background-color: #1D6064;
  text-align: center;
  font-family: 'Courier New', Courier, monospace;
  padding-top: 1px;
  font-size: 12px;
  color: white;
}
.barred{
  background-color: #CD5C5C	;
  text-align: center;
  font-family: 'Courier New', Courier, monospace;
  font-size: 12px;
  padding-top: 1px;

  color: white;
}

.baryellow{
  background-color: #FF8C00	;
  text-align: center;
  font-family: 'Courier New', Courier, monospace;
  font-size: 12px;
  padding-top: 1px;
  color: white
  ;
}

.bargrey{
  background-color: #696969	;
  text-align: center;
  font-family: 'Courier New', Courier, monospace;
  font-size: 12px;
  padding-top: 1px;
  color: white;
}

/* lbottom */

/* ltop */


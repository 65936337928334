/* 1 */

.r1{
position: absolute;
width: 13px;
height: 28px;
left: 117px;
top: 156px;
}


/* 6 */

.r6{
position: absolute;
width: 13px;
height: 26px;
left: 904px;
top: 506px;
}


/* 7 */

.r7{
position: absolute;
width: 13px;
height: 26px;
left: 1119px;
top: 441px;
}


/* 2 */

.r2{
position: absolute;
width: 14px;
height: 26px;
left: 388px;
top: 209px;
}


/* 5 */

.r5{
position: absolute;
width: 13px;
height: 26px;
left: 669px;
top: 532px;
}


/* 4 */

.r4{
position: absolute;
width: 13px;
height: 26px;
left: 428px;
top: 464px;
}


/* 3 */

.r3{
position: absolute;
width: 13px;
height: 26px;
left: 649px;
top: 327px;
}


/* 2 */

.p2{
position: absolute;
left: 225px;
top: 140px;
}



/* 1 */

.p1{
position: absolute;
left: 180px;
top: 130px;

}


/* 3 */

.p3{
position: absolute;
left: 270px;
top: 150px;
}

    
/* 4 */

.p4{
position: absolute;
left: 320px;
top: 152px;

}









/* 7 */

.p7{
    position: absolute;
    left: 530px;
    top: 220px;
    }
    
    
    /* 8 */
    
    .p8{
    position: absolute;
    left: 575px;
    top: 230px;
    }
    
    
    /* 6 */
    
    .p6{
    position: absolute;
    left: 490px;
    top: 200px;
    }
    
    
    /* 5 */
    
    .p5{
    position: absolute;
    left: 450px;
    top: 180px;
    }

/* 22 */

.p23{
    position: absolute;
    left: 1090px;
    top: 390px;
    
}


/* 21 */


.p21{
    position: absolute;
    left: 1020px;
    top: 430px;
}

.p22{
    position: absolute;
    left: 1060px;
    top: 415px;
    
}


/* 9 */

.p9{
position: absolute;
left: 690px;
top: 260px;
}


/* 10 */

.p10{
position: absolute;
left: 730px;
top: 270px;
}


/* 11 */

.p11{
position: absolute;
left: 770px;
top: 290px;
}


/* 12 */

.p12{
position: absolute;
left: 810px;
top: 310px;
}

.p13{
position: absolute;
left: 910px;
top: 380px;
}



/* 14 */

.p14{
position: absolute;
left: 945px;
top: 400px;
}


/* 15 */

.p15{
position: absolute;
left: 990px;
top: 415px;
}


/* 16 */

.p16{
position: absolute;
left: 1035px;
top: 405px;
}


/* 17 */

.p17{
position: absolute;
left: 770px;
top: 450px;
}


/* 18 */

.p18{
position: absolute;
left: 820px;
top: 460px;
}


/* 19 */

.p19{
position: absolute;
left: 865px;
top: 450px;
}


/* 20 */

.p20{
position: absolute;
left: 910px;
top: 440px;
}

.p24{
    /* 25 */


    position: absolute;
    left: 1130px;
    top: 370px;
    
    

}



.modalcontainer{
    position: fixed;
    top:-4%;
    left: 35%;
    height: auto;
    display: block;
    text-align: center;
    z-index:  99;
}

.modal{
    object-fit: contain;
}

.startbutton{
    position: absolute;
    bottom: 10%;
    background-image: url(../../../imgs/greenplay.png);
    background-color: transparent;
    background-position: center;
    background-repeat: no-repeat;
    border-radius: 0px;
    border:0px;

    background-size: 100% 100%;
    padding-top: 2%;
    padding-bottom: 2%;
    padding-left: 5%;
    padding-right: 5%;
    text-align: center;
    font-family: HighJinks;
    font-size: 24px;
    color: bisque;
    opacity:1;
    object-fit: contain;
}

.startbutton:hover{opacity: 1;
    filter: brightness(120%);
}

.startbutton:active{
    box-shadow: 0 5px transparent;
  transform: translateY(4px);

}

.p1name{
    position: absolute;
    bottom: 45%;
    left:17.8%;
    width:100px;

    text-align: center;
    font-family: 'Courier New', Courier, monospace;
    font-size: 14px;
    color: coral;

}
.p2name{

    position: absolute;
    bottom: 45%;
    right:17.8%;
    width:100px;

    text-align: center;
    font-family: 'Courier New', Courier, monospace;
    font-size: 14px;
    color: coral;
}
.timer{

    position: absolute;
    top: 50%;
    width: 100%;
    /* text-align:center; */

    text-align: center;
    font-family: 'Courier New', Courier, monospace;
    font-size: 14px;
    color: khaki;
}
.status{
    position: absolute;
    bottom: 5%;
    text-align:center;
    width: 100%;
    text-align: center;
    font-family: 'Courier New', Courier, monospace;
    font-size: 14px;
    color: khaki;
    display: flex;
    flex-direction: column; 
    justify-content:flex-start; 
    align-items: center;
}

.statustxt{
    flex:1;
    font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
    font-size: 15px;
    color: khaki;
}

.progress{

    flex:1;
    position: relative;
    bottom: 0%;
    display: block;
    left:40%;
    width:17%;
    height: 5%;
    display: flex;
    flex-direction: column; 
    justify-content:flex-start; 
    align-items: flex-start;
    font-family: 'Courier New', Courier, monospace;
    font-size: 14px;
    color: khaki;
}

.indicatorcontainer{

    position: relative;
    left:46%;
    object-fit: contain;
    display: block;
    text-align: center;
    font-family: 'Courier New', Courier, monospace;
    font-size: 24px;
    color: khaki;
}

.indicator{
    position: absolute;
    width: 100%;
    top:-40%;
    text-align: center;
    font-family: Impact, Haettenschweiler, 'Arial Narrow Bold', sans-serif;
    font-size: 24px;
    color: khaki;
}
.roomcontainer{
    display: flex;
    object-fit: contain;
    flex-direction: column;
    justify-content: space-between; 
    align-items: center;
    position: absolute;
    
    flex: 3;
    left:0;
    width: 100%;
    height: 80%;


    display: flex;
    flex-direction: column;

    justify-content: center;
    align-content: space-between;
}

.headingroom{
    padding: 1%;
    font-family: 'Courier New', Courier, monospace;
    font-size: 24px;
    color: khaki;
}

.freecontainer{
width: 45%;
height: 25%;
padding-right: 10%;
background-image: url('../../../imgs/roomborderfree.png');
background-size: contain;
object-fit: contain;
background-position: center;
background-repeat: no-repeat;

display: flex;
text-align: center;
flex-direction: column;
justify-content: space-between; 
align-items: center;
font-family: Impact, Haettenschweiler, 'Arial Narrow Bold', sans-serif;
font-size: 24px;
color: khaki;


}

.livebuttonhead{
    position: absolute;
    width: 27%;
    padding-top: 0.9%;
    padding-left: 2%;
    padding-right: 2%;
    display: flex;
    flex-direction: row;
    justify-content: space-around; 
    align-items: center;
    font-family: 'Courier New', Courier, monospace;
    font-size: 16px;
    color: khaki;   
}


.paybutton{
    left:31%;
    width: 100%;
    padding: 1%;
    display: flex;
    flex-direction: row;
    justify-content: space-around; 
    align-items: center;
    font-family: 'Courier New', Courier, monospace;
    font-size: 16px;
    color: khaki;   
}
.paybuttonhead{
    left:31%;
    width: 100%;
    padding-top: 3%;;
    padding-left: 11%;
    padding-right: 32%;
    display: flex;
    flex-direction: row;
    justify-content: space-around; 
    align-items: center;
    font-family: 'Courier New', Courier, monospace;
    font-size: 16px;
    color: khaki;   
}

.paybuttonrow{
    width: 100%;
    padding: 1%;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    padding-left: 5%; 
    align-items: center;
    font-family: 'Courier New', Courier, monospace;
    font-size: 16px;
    color: khaki;   
}


.freebuttoncontainer{
    position: absolute;
    top:18%;
    left:35%;
    width: 32%;
    height: 10%;
    max-height: 10%;
    overflow-y: scroll;
    object-fit: contain;
    font-family: 'Courier New', Courier, monospace;
    font-size: 16px;
    color: khaki;
}



.freebutton{
    flex: 1;
    
    object-fit: contain;
    padding-top: 1%;
    padding-right: 2%;

    display: flex;
    flex-direction: row;
    justify-content: space-evenly; 
    align-items: center;
    font-family: 'Courier New', Courier, monospace;
    font-size: 16px;
    color: khaki;
}




.playfee{
    flex:1;
    background-image : url('../../../imgs/greenplay.png');
    background-repeat: no-repeat;
    background-size: 100% 100%;
    background-size: contain;
    background-position: center;
    text-align: center;

    background-color: transparent;
    border-radius: 0px;
    border:0px;
    font-size: 18px;
    color: white;
    object-fit: contain;
    cursor: pointer;
    padding-top: 3%;
    padding-bottom: 3%;
}

.playfee2{
    flex:1;
    background-image : url('../../../imgs/greenplay.png');
    background-repeat: no-repeat;
    background-size: 100% 100%;
    background-size: contain;
    background-position: center;
    background-color: transparent;
    border-radius: 0px;
    border:0px;
    display: block;
    cursor: pointer;
    text-align: center;
    font-size: 20px;
    color: white;
    object-fit: contain;
    width:100%;
    padding-top: 3%;
    padding-bottom: 3%;
    /* opacity: 0.9; */
}

.txtfeeh{
    flex:1;
    font-family:'Courier New', Courier, monospace;
    font-size: 20px;
    padding-block-start: 2%;
    color: chocolate;
    text-align: center;
    object-fit: contain;
}

.txtfeenogame{
    width:100%;
    margin-left: 30%;
    margin-top: 7%;
    font-family:'Courier New', Courier, monospace;
    font-size: 20px;
    color: chocolate;
    text-align: center;
    object-fit: contain;
}


.txtfee{
    flex:1;
    font-family:'Courier New', Courier, monospace;
    font-size: 16px;
    padding-block-start: 2%;
    color: khaki;
    text-align: center;
    object-fit: contain;
}

.txtfee2{
    flex:1.5;
    font-family:'Courier New', Courier, monospace;
    font-size: 16px;
    padding-block-start: 2%;
    color: khaki;
    text-align: center;
    object-fit: contain;
}

.txtfee3{
    flex:1;
    font-family:'Courier New', Courier, monospace;
    font-size: 18px;
    color: khaki;
    text-align: center;
    object-fit: contain;
}

.txtfee4{
    flex:1.5;
    font-family:'Courier New', Courier, monospace;
    font-size: 18px;
    color: khaki;
    text-align: center;
    object-fit: contain;
}

.paidcontainer{
    background-image: url('../../../imgs/borderfee.png');
    width: 47%;
    height: 65%;
    padding-bottom: 4%;
    background-size: 100% 100%;
    object-fit: contain;
    background-position: center;
    background-repeat: no-repeat;   
    display: flex;
    flex-direction: column;
    justify-content: space-around; 
    align-items: center;
    
}

.paidcontainer1{
    width: 90%;
    height: 85%;
    max-height: 75%;
    overflow-y: scroll;
    background-size: contain;
    object-fit: contain;
    background-position: center;
    background-repeat: no-repeat;   
}

.sidebarcontainer{

    position: absolute;
    width:30%;
    height:20%;
    right:0%;
    top: 3%;
    object-fit: contain;
    display: felx;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-end;
}

.sidebarcontainer11{
    position: absolute;
    right:2%;
    width:50%;
    top:0%;
    height: auto;
    object-fit: contain,cover;
    background-image: url(../../../imgs/textbox.png);
    background-repeat: no-repeat;
    background-size: contain,cover;
    display: flex;
    flex-direction: row;
    justify-content: flex-start; 
    align-items: center;
}

.sidebarcontainer22{
    position: absolute;
    right:2%;
    width:50%;
    top:50%;


    height: auto;
    object-fit: contain,cover;
    background-image: url(../../../imgs/textbox.png);
    background-repeat: no-repeat;
    background-size: contain,cover;
    display: flex;
    flex-direction: row;
    justify-content: flex-start; 
    align-items: center;
}
::-webkit-scrollbar {
    width: 10px;
  }
  
  /* Track */
  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px #B9634C; 
    border-radius: 35px;
  }
   
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #97868A; 
    border-radius: 5px;
  }
  
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #97868A;     
}

.playfee:hover{
    opacity: 1;
    filter: brightness(120%);
}

.playfee2:hover{
    opacity: 1;
    filter: brightness(120%);

}

